html {
  height: 100%;
  width: 100%;
}
body {
  height: 100%;
  width: 100%;
  background-image: url(./asset/img/background-pc.jpg);
  background-repeat: no-repeat;
  background-image: 100% 100%;
}
#root {
  height: 100%;
  width: 100%;
}
.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.logo {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
}
.logo-container {
  margin-bottom: 100px;
}
.logo img {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 6px;
}
.app-name {
  font-family: PingFang SC;
  font-size: 32px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: -0.3333333432674408px;
  text-align: left;
  color: #fff;
  text-align: center;
}
.copy-button {
  display: inline-block;
  width: 192px;
}
.copy-button img {
  display: block;
  height: 100%;
  width: 100%;
}
.btn-container {
  text-align: center;
}
.web-site {
  width: 110px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.web-site img {
  display: block;
  width: 100%;
}

